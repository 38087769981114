function toggleMobileNavigation() {
    var x = document.getElementById("nav");
    if (x.className === "nav") {
        x.className += " responsive";
    } else {
        x.className = "nav";
    }
}

function navigate(elementId) {
    const element = document.getElementById(elementId);
    console.log('element: ', );
    
    window.scrollTo({
        top: element.offsetTop - 100,
        behavior: "smooth",
    });

    return false;
}